$(function() {
  $('.homes .w-dropdown-link').on('click', function(e) {
    $.ajax({
     url: '/wp-content/themes/verandah/lib/get-plans.php',
     type: 'post',
     data: {field: $(this).data('sort-field'), order: $(this).data('sort-order'), plans: $('.data-plans').text()},
     success: function(response) {
      $('.homes-wrap').html(response);
     }
    });
  });

  $('.move-in-ready-homes .w-dropdown-link').on('click', function(e) {
    $.ajax({
     url: '/wp-content/themes/verandah/lib/get-specs.php',
     type: 'post',
     data: {field: $(this).data('sort-field'), order: $(this).data('sort-order'), specs: $('.data-specs').text()},
     success: function(response) {
      $('.homes-wrap').html(response);
     }
    });
  });
});
